import { storeToRefs } from 'pinia'
import { useAppStore } from '@/store/app'
import { MODAL_TYPES } from '~/config/constant'

export default () => {
  const { $pinia } = useNuxtApp()
  const store = useAppStore($pinia)
  const { currentUser } = storeToRefs(store)

  const { openModalWithNavigate } = useModal()

  const userAmount = computed(() => {
    const amount = currentUser?.value?.balance
    return amount || 0
  })

  const avatarImage = computed(() => {
    const avatar = currentUser?.value?.avatar
    return avatar && avatar !== 'avatar-NaN'
      ? `/assets/images/event/avatar/${avatar}.webp`
      : '/assets/images/components/common/items/casino-item/default-avatar.svg'
  })

  const onClickAuthor = (modalName = MODAL_TYPES.LOGIN, url = 'login') => {
    openModalWithNavigate(modalName, url)
  }

  return {
    onClickAuthor,
    MODAL_TYPES,
    userAmount,
    avatarImage,
    currentUser
  }
}
